import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { format, addDays } from 'date-fns';
import image from "../assets/images/profil-picto.png";
import arrowlineleft from "../assets/icons/ArrowLineLeft-s.svg";
import ArrowLineRight from "../assets/icons/ArrowLineRight-s.svg";
import MenuHeader from "./MenuHeader";

const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  margin-left: 30px;
  margin-top: -80px;
`;

const Sidebar = styled.div`
    top: 145px;
    position: relative;
    width: 30%;
`;

const CalendarContainer = styled.div`
  flex: 1;
  padding: 20px;
  position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    top: 35px;
    left: 30px;
`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(840, 1fr);
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  background-color: #fff;
  height: 47px;
  padding: 0px 25px;
`;

const TimeSlot = styled.div`
  background-color: ${({ $status }) => {
    if ($status === 'available') return '#96ca63';
    if ($status === 'free') return '#8FA9B9';
    if ($status === 'overtime') return '#FAEF9B';
    return 'transparent';
  }}; 
  margin-bottom: 15px;
  top: 25px !important;
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 93px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #fff;
  font-family: Inter;
`;

const HourLabel = styled.div`
  width: 50px;
    position: relative;
    font-size: 11px;
    line-height: 16px;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.4);
    text-align: left;
    display: inline-block;
    height: 17px; 
    top: 15px;
    left: 0px;
`;

const TimeText = styled.div`
  color: ${({ status }) => (status === 'overtime' ? 'black' : 'white')};
  font-size: 14px;
  margin-bottom: 5px;
`;

const DurationText = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  color: ${({ status }) => (status === 'overtime' ? 'black' : 'white')};
  font-family: Inter;
  margin: 0;
  font-weight: 900;
  font-family: Lato;
`;

const CurrentTimeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${({ $left }) => $left}%;
  height: 80vh;
  width: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 11;
`;

const CurrentTimeIndicatorLine = styled.div`
  width: 2px;
  background-color: black;
  height: 100%;
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -70px;
  background-color: black;
  color: white;
  padding: 5px 8px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-family: Inter, sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  ${CurrentTimeWrapper}:hover & {
    display: block;
  }
`;

const sampleData = {
  '2024-10-23': [
    { therapist: 'Therapist 1', slots: [['8:00 AM', '12:30 PM', 'available'], ['12:30 PM', '5:15 PM', 'overtime']] },
    { therapist: 'Therapist 3', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '9:00 PM', 'overtime']]},
    { therapist: 'Therapist 2', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '8:00 PM', 'free'] ]},
    { therapist: 'Therapist 4', slots: [['8:00 AM', '4:00 PM', 'available'], ['4:00 PM', '6:30 PM', 'free'], ['6:30 PM', '8:30 PM', 'overtime']]},
  ],
  '2024-10-25': [
    { therapist: 'Therapist 1', slots: [['8:00 AM', '12:30 PM', 'available'], ['12:30 PM', '5:15 PM', 'overtime']] },
    { therapist: 'Therapist 3', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '9:00 PM', 'overtime']]},
    { therapist: 'Therapist 2', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '8:00 PM', 'free'] ]},
    { therapist: 'Therapist 4', slots: [['8:00 AM', '4:00 PM', 'available'], ['4:00 PM', '6:30 PM', 'free'], ['6:30 PM', '8:30 PM', 'overtime']]},
  ],
  '2024-10-27': [
    { therapist: 'Therapist 1', slots: [['8:00 AM', '12:30 PM', 'available'], ['12:30 PM', '5:15 PM', 'overtime']] },
    { therapist: 'Therapist 3', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '9:00 PM', 'overtime']]},
    { therapist: 'Therapist 2', slots: [['8:00 AM', '6:00 PM', 'available'], ['6:00 PM', '8:00 PM', 'free'] ]},
    { therapist: 'Therapist 4', slots: [['8:00 AM', '4:00 PM', 'available'], ['4:00 PM', '6:30 PM', 'free'], ['6:30 PM', '8:30 PM', 'overtime']]},
  ],
};

// Generate an array of hour labels
const generateHours = (startHour, endHour, interval) => {
  const hoursArray = [];
  for (let h = startHour; h <= endHour; h++) {
    for (let m = 0; m < 60; m += interval) {
      const hour = h % 24;
      const formattedHour = `${hour % 12 === 0 ? 12 : hour % 12}:${String(m).padStart(2, '0')} ${h >= 12 ? 'PM' : 'AM'}`;
      hoursArray.push(formattedHour);
    }
  }
  return hoursArray;
};

// Generate hourly labels from 8 AM to 9 PM with 1-minute intervals
const hours = generateHours(8, 21, 1);

const calculateDuration = (start, end) => {
  const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);
    if (modifier === 'PM' && hour !== 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;
    return hour * 60 + minute;
  };

  const startTotalMinutes = parseTime(start);
  const endTotalMinutes = parseTime(end);

  const durationMinutes = endTotalMinutes - startTotalMinutes;
  const totalMinutes = durationMinutes >= 0 ? durationMinutes : (24 * 60) + durationMinutes;

  const durationHours = Math.floor(totalMinutes / 60);
  const durationRemMinutes = totalMinutes % 60;

  return `${durationHours}:${String(durationRemMinutes).padStart(2, '0')}`;
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const dateString = format(currentDate, 'yyyy-MM-dd');
    setTimeSlots(sampleData[dateString] || []);
  }, [currentDate]);

  // Update current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    setCurrentTime(new Date());

    return () => clearInterval(interval);
  }, []);

  // Calculate the left position percentage for the current time indicator
  const calculateCurrentTimeLeft = () => {
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const startTime = 8 * 60;
    const endTime = 21 * 60 + 59;

    // If current time is outside the calendar range, do not display the indicator
    if (currentTotalMinutes < startTime || currentTotalMinutes > endTime) {
      return null;
    }

    const totalDisplayedMinutes = 840; // Total minutes from 8 AM to 9 PM
    const elapsedMinutes = currentTotalMinutes - startTime;
    const leftPercentage = (elapsedMinutes / totalDisplayedMinutes) * 100;

    return leftPercentage;
  };

  const currentTimeLeft = calculateCurrentTimeLeft();

  const changeDate = (days) => {
    setCurrentDate((prevDate) => addDays(prevDate, days));
  };

  const renderHourLabels = () => {
    return hours.map((hour, index) => {
      const isHourLabel = index % 60 === 0;

      if (isHourLabel) {
        const [time, modifier] = hour.split(' ');
        const hourOnly = time.split(':')[0];
        const displayHour = `${hourOnly} ${modifier}`;
        
        return (
          <HourLabel key={index}>{displayHour}</HourLabel>
        );
      } else {

        return (
          <div key={index} style={{ height: '40px', width: '0px' }}></div>
        );
      }
    });
  };

  // Convert 12-hour format time to 24-hour format
  const formatTimeTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    if (modifier === 'PM' && hour < 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  };

  const renderTimeSlots = () => {
    return timeSlots.flatMap((therapist, tIndex) => 
      therapist.slots.map(([start, end, status], slotIndex) => {
        const startIndex = hours.indexOf(start) + 1;
        const endIndex = hours.indexOf(end) + 1;

        return (
          <TimeSlot 
            key={`${tIndex}-${slotIndex}`}
            $status={status}
            style={{ gridColumn: `${startIndex} / span ${endIndex - startIndex}` }}
          >
            <TimeText status={status}>
              {status === 'overtime'
                ? `Overtime ${formatTimeTo24Hour(start)} - ${formatTimeTo24Hour(end)}`
                : status === 'free'
                ? 'Free Time'
                : `${formatTimeTo24Hour(start)} - ${formatTimeTo24Hour(end)}`}
            </TimeText>  
            <DurationText status={status}>
              {status === 'free' ? '' : `${calculateDuration(start, end)}`}
            </DurationText>
          </TimeSlot>
        );
      })
    );
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search Promotion code"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <div className="select">
              <select>
                <option value="">Region</option>
                <option value="Name">Region</option>
              </select>
            </div>
            <div className="select">
              <select>
                <option value="">Teams</option>
                <option value="Name">Teams</option>
              </select>
            </div>
          </div>
          <div className='actions'>
            <button className="btn-download">
              Download template 
            </button>
            <button className="btn-add-client">
              Import
            </button>
          </div>
        </div>
      </div>

      <Header>
        <div className="group-cal">
          <div className="button-cal">
            <div className="iconset-cal">
              <img 
                onClick={() => changeDate(-1)} 
                className="arrowlineleft-s-icon-cal" 
                alt="Previous Day" 
                src={arrowlineleft} 
              />
            </div>
          </div>
          <div className="text-cal">
            <div className="text1-cal">
              {currentDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
            </div>
          </div>
          <div className="button-cal">
            <div className="iconset-cal">
              <img 
                onClick={() => changeDate(1)} 
                className="arrowlineleft-s-icon-cal" 
                alt="Next Day" 
                src={ArrowLineRight} 
              />
            </div>
          </div>
          <div className="button2-cal">
            <div className="text-cal">
              <div className="text1-cal" onClick={() => setCurrentDate(new Date())}>
                Today
              </div>
            </div>
          </div>
        </div>
      </Header>

      <Container>
        <Sidebar>      
          {timeSlots.map((therapist, index) => (        
            <div key={index} className="list-item-with-image-calendar">
              <img src={image} alt="avatar" className="avatar-img" />
              <div className="therapist-items">
                <div className="title-therapist-item">
                  <div className="name-therapist-calendar">{therapist.therapist}</div>
                  <div className="address-therapist-calendar">{'8001 Zürich'}</div>
                </div>
                <div className="details-therapist-calendar">
                  <div className="details-items">
                    <p className="availability-therapist">Available: 3.75 Hrs</p>
                    <p className="availability-therapist">Booked Time: 20.25 Hrs</p>
                    <p className="availability-therapist">Availability: 15.6%</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Sidebar>
        
        <CalendarContainer>
          <TimeSlots>
            {renderHourLabels()}
            {renderTimeSlots()}

            {currentTimeLeft !== null && (
              <CurrentTimeWrapper $left={currentTimeLeft}>
                <CurrentTimeIndicatorLine />
                <Tooltip>
                  {format(currentTime, 'hh:mm a')}
                </Tooltip>
              </CurrentTimeWrapper>
            )}
          </TimeSlots>
        </CalendarContainer>
      </Container>
    </>
  );
};

export default Calendar;
import MenuHeader from './MenuHeader';
import Breadcrumb from './Breadcumb/Breadcrumb';
import {Map} from './google_map';
import ListItem from './List_item/ListItem';
import React , { Fragment } from 'react'

const Dashboard = () => {
    return (
    <Fragment>
        <MenuHeader />
        <Breadcrumb />
        <Map />
        <ListItem />
    </Fragment>
    );
  }
  
  export default Dashboard;
import "./Breadcrumb.css";
import React, { useState } from 'react';
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { StaticDatePicker  } from '@mui/x-date-pickers/StaticDatePicker';
import { CiClock2 } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    height: '680px',
  },
};

const timerStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    maxWidth: '350px',
    padding: '0px 0px 0px 0px',
  },
};

const Breadcrumb = () => {

  Modal.setAppElement('#root');

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = useState('map');
  const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
  const [value, setValue] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const [formData, setFormData] = useState({
    category: "",
    subategory: "",
    product: "",
    duration: "",
    dateBooking: "",
    timeBooking: "",
    comment: "",
    customerName: "",
    customerEmail: "",
    customerAddress: "",
    postcode: "",
    customerMobileNumber: "",
    flyproName: "",
    promoCode: "",
  });

  const handleButtonClick = (button) => {
    setSelected(button);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setFormData({
      category: "",
      subategory: "",
      product: "",
      duration: "",
      dateBooking: "",
      timeBooking: "",
      comment: "",
      customerName: "",
      customerEmail: "",
      customerAddress: "",
      postcode: "",
      customerMobileNumber: "",
      flyproName: "",
      promoCode: "",
    });
    setIsOpen(false);
  }

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  const handleOkClick = () => {
    setValue(selectedTime);
    const timeString = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setFormData({
      ...formData,
      timeBooking: timeString,
    });
    setTimePickerIsOpen(false);
  };

  const handleCancelClick = () => {
    setTimePickerIsOpen(false);
  };

  const openDatePicker = () => {
    setDatePickerIsOpen(true);
  };
  
  const closeDatePicker = () => {
    setDatePickerIsOpen(false);
  };

  return (
    <>
      <div className="bloc-search background-breadcrumb">
        <div className="container">
          <div className="search">
          <div className='breadcrumb-navigation'>
            <button
              className={`breadcrumb-map-button ${selected === 'map' ? 'active' : ''}`}
              onClick={() => handleButtonClick('map')}
            >
              <div className={`icon ${selected === 'map' ? 'active-icon' : ''}`} />
              <span className='breadcrumb-label'>Map</span>
            </button>
            
            <button
              className={`breadcrumb-list-button ${selected === 'list' ? 'active' : ''}`}
              onClick={() => handleButtonClick('list')}
            >
              <div className={`icon ${selected === 'list' ? 'active-icon' : ''}`} />
              <span className='breadcrumb-label-list'>List</span>
            </button>
          </div>
          <div className="select">
            <select>
              <option value="">04/07/2024</option>
              <option value="Name">04/07/2024</option>
            </select>
          </div>
          <div className="select">
            <select>
              <option value="">9:00 - 19:00</option>
              <option value="Name">9:00 - 19:00</option>
            </select>
          </div>
          <div className="select">
            <select>
              <option value="">Name</option>
              <option value="Name">name</option>
            </select>
          </div>
          <div className="select">
            <select>
              <option value="">Exact Search</option>
              <option value="Name">Exact Search</option>
            </select>
          </div>
            
          </div>
          <a onClick={openModal} className="btn-add-client">
            Créer une tâche
          </a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={closeModal}>close</button>
        <h2>Créer une tâche</h2>
        <p>If you need more info, please check out</p>
        <div className="form-inscription">
          <form >
            <div className="form-text">
              <label>Category</label>
              <input type="text" name="category" onChange={handleChange} />
            </div>  
            <div className="form-text">
              <label>Subategory</label>
              <input type="text" name="subategory" onChange={handleChange} />
            </div>
            <div className="form-text">
              <label>Product</label>
              <input type="text" name="product" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Duration</label>
              <input type="date" name="duration" onChange={handleChange} />
            </div>

            <div className="dates">
              <div className="form-text dateOfTheBookingParent topMarg">
                <label>Date of the booking</label>
                <input
                  type="text"
                  name="dateBooking"
                  value={formData.dateBooking}
                  onClick={openDatePicker}
                  readOnly
                />
                <CiCalendar className="date-icon" onClick={openDatePicker} />
              </div>

              <div className="form-text timeOfTheBookingParent topMarg">
                <label>Time of the booking</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={formData.timeBooking}
                  onClick={() => setTimePickerIsOpen(true)}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => setTimePickerIsOpen(true)}/>
              </div>
            </div>

            <div className="form-text topMargArea">
              <label>Comment</label>
              <textarea className="formTextArea" type="textarea" name="comment" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Customer name</label>
              <input type="text" name="customerName" onChange={handleChange} />
            </div>  

            <div className="form-text">
              <label>Customer email</label>
              <input type="text" name="customerEmail" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Customer address</label>
              <input type="text" name="customerAddress" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Postcode</label>
              <input type="text" name="postcode" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Customer mobile phone number</label>
              <input type="text" name="customerMobileNumber" onChange={handleChange} />
            </div>  
            
            <div className="form-text">
              <label>Flypro name</label>
              <input type="text" name="flyproName" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Promo CODE</label>
              <input type="text" name="promoCode" onChange={handleChange} />
            </div>

            <button type="submit" className="btn-submit">
              Créer la tâche
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={datePickerIsOpen}
        onRequestClose={closeDatePicker}
        style={timerStyles}
        contentLabel="Date Picker Modal"
      >
        <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker 
              label="Select Date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-date" onClick={closeDatePicker}>Cancel</button>
              <button className="action-date" onClick={() => {
                const dateString = value.toLocaleDateString();
                setFormData({
                  ...formData,
                  dateBooking: dateString,
                });
                closeDatePicker();
              }}>OK</button>
            </div>
          </LocalizationProvider>
        </div>

      </Modal>

      <Modal
        isOpen={timePickerIsOpen}
        onRequestClose={handleCancelClick}
        style={timerStyles}
        contentLabel="Time Picker Modal"
      >
        <div className="time-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={selectedTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-timer" onClick={handleCancelClick}>Cancel</button>
              <button className="action-timer" onClick={handleOkClick}>OK</button>
            </div>
          </LocalizationProvider>
        </div>
      </Modal>
    </>
  );
}

export default Breadcrumb;
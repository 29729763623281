import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import { endpoints, exportClientBookingsUrl, exportDirectHotelBookingsUrl, getHotelBookingsUrl, createHotelAccount } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
  },
};

const Hotels = () => {
  Modal.setAppElement("#root");

  const handleExportClientBookings = async (hotelId) => {
    const url = exportClientBookingsUrl(hotelId);
    try {
      // Fetch the file as blob
      const response = await axiosPrivate.get(url, {
        responseType: 'blob',
      });
  
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob); 
      a.download = 'client_bookings.xlsx'; 
  
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting client bookings:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'An error occurred!',
      });
    }
  };
  
  const handleExportDirectHotelBookings = async (hotelId) => {
    try {
      const url = exportDirectHotelBookingsUrl(hotelId);
      
      // Fetch the file as blob
      const response = await axiosPrivate.get(url, {
        responseType: 'blob', 
      });
  
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Create a temporary link to trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'direct_hotel_bookings.xlsx';
      
      document.body.appendChild(a);
      a.click(); 
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error exporting direct hotel bookings:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data?.message || 'An error occurred during export.',
      });
    }
  };
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [allBookings, setAllBookings] = useState([]);
  const [directBookings, setDirectBookings] = useState([]);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [accountFormData, setAccountFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
  });

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phoneNumber: "",
    hotelEmail: "",
    contactFirstName: "",
    contactLastName: "",
    flyproInstructions: "",
    duoCapability: false,
  });

  const axiosPrivate = useAxiosPrivate();

  // Fetch Hotels
  const fetchHotels = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(endpoints.getHotels);
      if (Array.isArray(response.data)) {
        setHotels(response.data);
      } else if (response.data.hotels) {
        setHotels(response.data.hotels);
      } else {
        throw new Error("Unexpected response structure");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching hotels:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    }
  };

  const fetchBookings = async (hotelId) => {
    try {
        const url = getHotelBookingsUrl(hotelId);
        const response = await axiosPrivate.get(url);
        setAllBookings(response.data.allBookings);
        setDirectBookings(response.data.directBookings);
    } catch (error) {
        console.error("Error fetching bookings:", error);
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response?.data?.message || "An error occurred!",
        });
    }
  };


  useEffect(() => {
    fetchHotels();
  }, []);

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };


  const closeModalManage = () => {
    setIsOpenManage(false);
    setSelectedHotel(null);
  };

  const openModalManage = (hotel) => {
    setSelectedHotel(hotel);
    fetchBookings(hotel.id);
    setIsOpenManage(true);
};

  const handleSwitchChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      duoCapability: event.target.checked,
    }));
  };

  const handleAccountFormChange = (e) => {
    const { name, value } = e.target;
    setAccountFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post(endpoints.createHotel, formData);
      if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred! " + response.data.response,
        });
        return;
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Hotel created successfully!",
        showConfirmButton: false,
        timer: 1500,
      });

      fetchHotels(); // Refresh the hotel list
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    }
  };

  const handleAccountFormSubmit = async (e, hotelId) => {
    e.preventDefault();
  
    if (!accountFormData.email || !accountFormData.firstName || !accountFormData.lastName || !accountFormData.password) {
      Swal.fire({
        icon: "error",
        title: "Missing Fields",
        text: "Please fill out all required fields!",
      });
      return;
    }
  
    try {
      const url = createHotelAccount(hotelId);  
      const response = await axiosPrivate.post(url, accountFormData);
  
      if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message || "An error occurred while creating the account.",
        });
        return;
      }
  
      Swal.fire({
        icon: "success",
        title: "Account Created",
        text: "The hotel account was created successfully!",
      });
  
      setIsAccountModalOpen(false);
  
    } catch (error) {
      console.error("Error creating account:", error);
  
      // Check if error has a response and a message
      let errorMessage = "An unexpected error occurred.";
      if (error.response) {
        // Handle specific error types like validation, unique constraint violations, etc.
        if (error.response.status === 400 && error.response.data) {
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (error.response.data.errors) {
            errorMessage = error.response.data.errors.join(", ");
          }
        } else if (error.response.status === 500) {
          errorMessage = "Internal server error. Please try again later.";
        } else if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
      }
  
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    }
  };
  
  const openModal = () => {
    setIsOpen(true);
    setFormData({
      name: "",
      address: "",
      phoneNumber: "",
      hotelEmail: "",
      contactFirstName: "",
      contactLastName: "",
      flyproInstructions: "",
      duoCapability: false,
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Open account modal
  const handleCreateAccountClick = () => {
    setIsAccountModalOpen(true);
  };

  // Close account modal
  const handleModalClose = () => {
    setIsAccountModalOpen(false);
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input type="text" placeholder="Search by Hotel Name..." className="form-text" />
              <input type="submit" className="form-submit" value="Search" />
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Add a Hotel
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead className="no-background">
              <tr>
                <th>Hotel name</th>
                <th>Hotel address</th>
                <th>Contact Name</th>
                <th>Phone Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : hotels.length > 0 ? (
                hotels.map((hotel) => (
                  <tr key={hotel.id}>
                    <td>{hotel.name}</td>
                    <td>{hotel.address}</td>
                    <td>{hotel.contactFirstName + " " + hotel.contactLastName}</td>
                    <td>{hotel.phone}</td>
                    <td>
                        <a href="#" onClick={() => openModalManage(hotel)} className="see-more edit">
                            See more
                        </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No hotels found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Hotel"
      >
        <button onClick={closeModal}>Close</button>
        <h2>Add a Hotel</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>Hotel Name*</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Address*</label>
              <input type="text" name="address" value={formData.address} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Contact First Name*</label>
              <input type="text" name="contactFirstName" value={formData.contactFirstName} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Contact Last Name*</label>
              <input type="text" name="contactLastName" value={formData.contactLastName} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Phone Number*</label>
              <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Hotel Email</label>
              <input type="text" name="hotelEmail" value={formData.hotelEmail} onChange={handleChange} />
            </div>
            <div className="form-text topMargArea">
              <label>Flypro Instructions</label>
              <textarea
                className="formTextArea"
                value={formData.flyproInstructions}
                name="flyproInstructions"
                placeholder="Enter Flypro Instructions..."
                onChange={handleChange}
              />
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.duoCapability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <button type="submit" className="btn-submit">
              Create a Hotel
            </button>
          </form>
        </div>
      </Modal>

      <Modal
          isOpen={modalIsOpenManage && selectedHotel !== null}
          onRequestClose={closeModalManage}
          style={customStyles}
          contentLabel="Manage Hotel"
      >
        <div className="form-inscription mrgBottom">
          <h2>{selectedHotel?.name}</h2>
          <form>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Address</label>
              <input  type="text" name="address" value={selectedHotel?.address || ''} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Contact Name:</label>
              <input type="text" name="contactFirstName" value={`${selectedHotel?.contactFirstName || ''} ${selectedHotel?.contactLastName || ''}`.trim()} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Phone Number*</label>
              <input type="text" name="phoneNumber" value={selectedHotel?.phone || ''} readOnly />
            </div>
            <div className="input-hotel-container">
              <label className="input-hotel-label">Hotel Email</label>
              <input type="text" name="hotelEmail" value={selectedHotel?.email || ''} readOnly />
            </div>
            <div className="account-hotel-management">
              <div className="input-hotel-container">
                <label htmlFor="accountManagement" className="input-hotel-label">Account Management</label>
                <input 
                  type="text" 
                  id="accountManagement" 
                  name="accountManagement" 
                  readOnly 
                  value=" " 
                />
                {selectedHotel?.account ? (
                  <span className="hotel-account">{selectedHotel.account.fullName}</span>
                ) : (
                  <a href="#" onClick={(e) => handleCreateAccountClick(e, selectedHotel.id)} className="create-hotel-account">
                    Create Account
                  </a>
                )}
              </div>
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginRight: '220px' }}>
                <Typography>DUO Capability</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={selectedHotel?.duoCapability}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'DUO Capability' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>
            <div className="form-text booking-zone topMargArea">
                <span className="list-all-bookings">Hotel Bookings:</span>
                <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
                <table className="table-bookings">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Client</th>
                            <th>Date</th>
                            <th>Treatment Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBookings.length > 0 ? (
                            allBookings.map((booking) => (
                                <tr key={booking.id}>
                                    <td>{booking.id}</td>
                                    <td>{booking.client}</td>
                                    <td>{booking.date ? new Date(booking.date).toISOString().split('T')[0] : 'N/A'}</td>
                                    <td>{booking.treatmentType}</td>
                                    <td>{booking.status}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No bookings found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="extract-button" onClick={() => handleExportClientBookings(selectedHotel.id)}>
                    Extract Booking
                </div>
            </div>
            <div className="form-text booking-zone extractMrg">
                <span className="list-all-bookings">Hotel Direct Bookings:</span>
                <span className="list-excel-all-bookings">EXTRACT Booking List in EXCEL</span>
                <table className="table-bookings">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Client</th>
                            <th>Date</th>
                            <th>Treatment Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {directBookings.length > 0 ? (
                            directBookings.map((booking) => (
                                <tr key={booking.id}>
                                    <td>{booking.id}</td>
                                    <td>{booking.client}</td>
                                    <td>{booking.date ? new Date(booking.date).toISOString().split('T')[0] : 'N/A'}</td>
                                    <td>{booking.treatmentType}</td>
                                    <td>{booking.status}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No direct bookings found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="extract-button" onClick={() => handleExportDirectHotelBookings(selectedHotel.id)}>
                    Extract Booking
                </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
        contentLabel="Create Hotel Account"
      >
        <button onClick={handleModalClose}>Close</button>
        <h2>Create Hotel Account</h2>
        <form onSubmit={(e) => handleAccountFormSubmit(e, selectedHotel.id)}>
           <div className="form-inscription mrgBottom">
            <div className="form-text">
              <label>Email*</label>
              <input
                type="email"
                name="email"
                value={accountFormData.email}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>First Name*</label>
              <input
                type="text"
                name="firstName"
                value={accountFormData.firstName}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastName"
                value={accountFormData.lastName}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Phone</label>
              <input
                type="text"
                name="phone"
                value={accountFormData.phone}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <div className="form-text">
              <label>Password*</label>
              <input
                type="password"
                name="password"
                value={accountFormData.password}
                onChange={handleAccountFormChange}
                required
              />
            </div>
            <button type="submit" className="btn-submit">
              Create Account
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Hotels;

import React, { useEffect, useState } from "react";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: "hidden auto",
    scrollbarWidth: "none",
    height: "650px",
  },
};

const User = () => {

  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    role: "ROLE_ADMIN",
    type: "admin", 
    civility: "", 
    birthdate: "",
    zip: "", 
  });

  const axiosPrivate = useAxiosPrivate();

  const fetchUsers = async () => {
    try {
      const adminResponse = await axiosPrivate.get(`${endpoints.getAdminUser}/?role=ROLE_ADMIN`);
  
      setUsers(adminResponse.data.users);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred!",
      });
    }
  };
  

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const dataToSubmit = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone,
      birthdate: formData.birthdate,
      zipcode: formData.zip,
      role: formData.role === 'ROLE_ADMIN' ? 'Administrator' : formData.role,
      type: formData.type,
      civility: formData.civility,
    };
  
    // Check for existing email
    const emailExists = users.some(user => user.email === formData.email);
    if (emailExists) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "A user with this email already exists!",
      });
      return;
    }
  
    try {
      const response = await axiosPrivate.post(endpoints.invite, dataToSubmit);
      
      if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred! " + response.data.response,
        });
        return;
      }
  
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Account created successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("Created User Data:", response.data);

      fetchUsers();
      closeModal();
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred!";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: errorMessage,
      });
      console.error("Error submitting form:", error);
    }
  };

  const openModal = () => {
    setIsOpen(true);
    setFormData({
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      role: "ROLE_ADMIN",
      type: "admin", 
      civility: "", 
      birthdate: "",
      zip: "", 
    });
  };

  const closeModal = () => {
    setFormData({
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zipcode: "",
      type: "admin"
    });
    setIsOpen(false);
  };

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                placeholder="Search by City or Team/Zone..."
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Add a manager
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Team</th>
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="10">Loading...</td>
                </tr>
              ) : Array.isArray(users) && users.length > 0 ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <img src={image} alt="User Profile" />
                    </td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{user.therapistScore}</td>
                    <td>
                      {user.roles.includes('ROLE_ADMIN') ? 'Administrator' : user.status}
                    </td>
                    <td>
                      <a href="#" className="edit">&#8942;</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No user found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Manager"
      >
        <button onClick={closeModal}>Close</button>
        <h2>Add a Manager</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <label>First Name</label>
              <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Last Name</label>
              <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Date of Birth (optional)</label>
              <input type="date" name="birthdate" value={formData.birthdate} onChange={handleChange} />
            </div>
            <div className="form-text">
              <label>Mobile Phone Number</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>Email Address</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-text">
              <label>ZIP Code</label>
              <input type="text" name="zip" value={formData.zip} onChange={handleChange} required />
            </div>
            <div className="form-select">
              <label>Status</label>
              <select name="role" value={formData.role} onChange={handleChange} required>
                <option value="">Select a role</option>
                <option value="ROLE_ADMIN">Admin</option>
              </select>
            </div>
            <button type="submit" className="btn-submit">
              Create a manager
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default User;

import styles from './ListTable.module.css';
import Table from './TaskTable';
import React , { Fragment } from 'react'
import Header from '../Header/Header';
import Breadcrumb from '../Breadcumb/Breadcrumb';

export default function ListTable () {

const sampleData = [
    { task: '522891911', order: 578700, team: 'New York', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891912', order: 578701, team: 'San Francisco', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891911', order: 578700, team: 'New York', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891912', order: 578701, team: 'San Francisco', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
    { task: '522891913', order: 578702, team: 'Chicago', tags: '-', description: '522891913', flyingDetails: 578702, name: 'Chicago', address: '-', createdBy: '-', startBefore: '522891913', completeBefore: 578702, reviewRating: 'Chicago', taskStatus: '-' },
];

return (
    <Fragment>
        <Header />
        <Breadcrumb />
        
        <div className={styles.searchByNameParent}>
            <div className={styles.searchByName}>Search by Name</div>
            <button className={styles.button}>
                <div className={styles.bookATreatment}>Search</div>
            </button>
        </div>

        <div className={styles.frameParent}>
            <button className={styles.nameParent}>
                <div className={styles.name}>Name</div>
                <span className={styles.chevronDownIcon}/>
            </button>
            <button className={styles.containsParent}>
                <div className={styles.name}>Contains</div>
                <span className={styles.chevronDownIcon}/>
            </button>
            <button className={styles.allFiltersParent}>
                <div className={styles.name}>All Filters</div>
                <span className={styles.chevronDownIcon}/>
            </button>
            <button className={styles.allTagsParent}>
                <div className={styles.name}>All Tags</div>
                <span className={styles.chevronDownIcon}/>
            </button>
        </div>  

        <div className={styles.scrollableTableContainer}>
            <Table data={sampleData}  />
        </div>
    </Fragment> 
    );
};
                  									
import React from 'react';
import styles from './ListTable.module.css';

const Table = ({ data }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.customTable}>
        <thead className={styles.tableHeader}>
          <tr>
            <th className={styles.tableHeaderCell}>Task ID</th>
            <th className={styles.tableHeaderCell}>ORDER ID</th>
            <th className={styles.tableHeaderCell}>TEAM NAME</th>
            <th className={styles.tableHeaderCell}>TAGS</th>
            <th className={styles.tableHeaderCell}>DESCRIPTION</th>
            <th className={styles.tableHeaderCell}>FLYING DETAILS</th>
            <th className={styles.tableHeaderCell}>NAME</th>
            <th className={styles.tableHeaderCell}>ADDRESS</th>
            <th className={styles.tableHeaderCell}>CREATED BY</th>
            <th className={styles.tableHeaderCell}>START BEFORE</th>
            <th className={styles.tableHeaderCell}>COMPLETE BEFORE</th>
            <th className={styles.tableHeaderCell}>REVIEW / RATING</th>
            <th className={styles.tableHeaderCell}>TASK STATUS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={styles.tableRow}>
              <td className={`${styles.tableCell} ${styles.taskColor}`}>{item.task}</td>
              <td className={styles.tableCell}>{item.order}</td>
              <td className={styles.tableCell}>{item.team}</td>
              <td className={styles.tableCell}>{item.tags}</td>
              <td className={styles.tableCell}>{item.description}</td>
              <td className={styles.tableCell}>{item.flyingDetails}</td>
              <td className={styles.tableCell}>{item.name}</td>
              <td className={styles.tableCell}>{item.address}</td>
              <td className={styles.tableCell}>{item.createdBy}</td>
              <td className={styles.tableCell}>{item.startBefore}</td>
              <td className={styles.tableCell}>{item.completeBefore}</td>
              <td className={styles.tableCell}>{item.reviewRating}</td>
              <td className={styles.tableCell}>{item.taskStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import imageFile from "../assets/images/upload-file.svg";
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    boxshadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    overflowX: "hidden",
    scrollbarWidth: "none",
    height: "680px",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Product = () => {
  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [domain, setDomain] = useState([]);
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1); // Total des pages
  const limit = 100; // Nombre de clients par page
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");

  const [formData, setFormData] = useState({
    id:"",
    category_id: "",
    domaine_id: "",
    subcategory_id: "",
    product_id: "",
    name: "",
    duration: "",
    type: "product",
    is_active: 1,
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;
  
    if (formData.type === "product" || formData.type === "subproduct") {
      if (name === "domaine_id") {
        try {
          const response = await axiosPrivate.get(
            `${endpoints.getCategoryByDomaine}/${value}`
          );
          
          if (response.data.status === false) {
            setCategories([]);
          } else {
            setCategories(response.data);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des catégories:", error);
        }
      }
  
      // Handle category selection
      if (name === "category_id") {
        try {
          // Fetch subcategories by category ID
          const subCategoryResponse = await axiosPrivate.get(
            `${endpoints.getSubCategoryByCategory}/${value}`
          );
  
          if (subCategoryResponse.data.status === false) {
            setSubCategories([]);
          } else {
            setSubCategories(subCategoryResponse.data);
          }
  
          // Fetch products by category ID
          const productResponse = await axiosPrivate.get(
            `${endpoints.getProductByCategory}/${value}`
          );
  
          if (productResponse.data.status === false) {
            setProductList([]);
          } else {
            setProductList(productResponse.data);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des sous-catégories ou produits:", error);
        }
      }
  
      // Handle subcategory selection
      if (name === "subcategory_id") {
        try {
          const response = await axiosPrivate.get(
            `${endpoints.getProductBySubCategory}/${value}`
          );
  
          if (response.data.status === false) {
            setProductList([]);
          } else {
            setProductList(response.data);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des produits par sous-catégorie:", error);
        }
      }
    }
  
    // Handle file input
    if (name === "file") {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      type: "", 
      name: "",
      description: "",
      duration: "",
      price: "",
      domain_id: "",
      category_id: "",
      subcategory_id: "",
      is_active: 1,
      file: null,
      imageFile: "",
      subproduct: "", 
    });
  
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  
    setFileName("");
  };
  
  const fetchProducts = async () => {
    try {
      const [response, domaineResponse, categoryResponse] = await Promise.all([
        axiosPrivate.get(
          `${endpoints.getProduct}/?page=${currentPage + 1}&limit=${limit}`
        ), // L'URL inclut la page et la limite
        axiosPrivate.get(
          `${endpoints.getDomain}/?page=${currentPage + 1}&limit=${limit}`
        ), // L'URL inclut la page et la limite
        axiosPrivate.get(
          `${endpoints.getCategory}/?page=${currentPage + 1}&limit=${limit}`
        ), // L'URL inclut la page et la limite
      ]);
      setProducts(response.data); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
      setTotalPages(response.data.totalPages || 2); // Le nombre total de pages si disponible dans la réponse
      setLoading(false);
      setDomain(domaineResponse.data);
      setCategory(categoryResponse.data);
    } catch (error) {
      console.error("Erreur lors du chargement des donnée:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const handleSwitchChange = (index, id) => (event) => {
    axiosPrivate
    .post(endpoints.ActiveProduct,
      {id}
    )
    .then((response) => {
      if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenue! " + response.data.message,
        });
      } else {
        closeModal();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Modification effectué",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Form submitted successfully:", response.data);
        fetchProducts();
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenue! " + error,
      });
      console.error("Error submitting form:", error);
    });
    const updatedRows = [...products];
    updatedRows[index].status = event.target.checked ? "Active" : "Inactive";
    setProducts(updatedRows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
console.log((formData.type))
    // Créer une nouvelle instance de FormData
    const formDataToSend = new FormData();

    // Ajouter les données de formData (y compris les fichiers) à formDataToSend
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    // Si vous avez un fichier dans un champ 'file', vous devez l'ajouter explicitement
    if (file) {
      formDataToSend.append("logo", file);
    } else {
      if(!formData.id && formData.type != "product"){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Merci de selectionner un logo ",
        });
        return;
      }
      
    }

    
    // Envoyer les données via Axios
    if(formData.id){
      axiosPrivate
      .post(
        formData.type == "product"
           ? `${endpoints.editProduct}/${formData.id || ""}`
    : formData.type === "category"
    ? `${endpoints.editCategory}/${formData.id || ""}`
    : formData.type === "subcategory"
    ? `${endpoints.editSubCategory}/${formData.id || ""}`
    : formData.type === "subproduct"
    ? `${endpoints.editSubProduct}/${formData.id || ""}`
    : `${endpoints.editDomaine}/${formData.id || ""}`,
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une erreur est survenue! " + response.data.message,
          });
        } else {
          closeModal();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: formData.type + " modifié",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log("Form submitted successfully:", response.data);
          fetchProducts();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenue! " + error,
        });
        console.error("Error submitting form:", error);
      });

    }
    
      else
      axiosPrivate
      .post(
        formData.type == "product"
          ? endpoints.newProduct
          : formData.type == "category"
          ? endpoints.newCategory
          : formData.type == "subcategory"
          ? endpoints.newSubCategory
          : formData.type == "subproduct"
          ? endpoints.newSubProduct
          : endpoints.newDomaine,
        formDataToSend,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une erreur est survenue! " + response.data.message,
          });
        } else {
          closeModal();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: formData.type + " créé",
            showConfirmButton: false,
            timer: 1500,
          });
          console.log("Form submitted successfully:", response.data);
          fetchProducts();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenue! " + error,
        });
        console.error("Error submitting form:", error);
      });
  };

  async function openModal(product, type) {
    resetForm();
    if (product) {
      const domaine_id = product.category?.domaine?.id || "";
      const category_id = product.category?.id ||  "";
      const subcategory_id = product.subcategory?.id || "";
  
      if (domaine_id) {
        try {
          const response = await axiosPrivate.get(
            `${endpoints.getCategoryByDomaine}/${domaine_id}`
          );
          
          if (response.data.status === false) {
            setCategories([]);
          } else {
            setCategories(response.data);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des catégories:", error);
        }
      }
  
      if (category_id) {
        try {
          const subCategoryResponse = await axiosPrivate.get(
            `${endpoints.getSubCategoryByCategory}/${category_id}`
          );
          
          if (subCategoryResponse.data.status === false) {
            setSubCategories([]);
          } else {
            setSubCategories(subCategoryResponse.data);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des catégories:", error);
        }
      }
  
      if (type === "product") {
        setFormData({ ...formData, ...product, domaine_id, category_id, subcategory_id, type });
      } else {
        const name = type === "domaine" ? product.category.domaine.title : type === "category" ? product.category.title : product.subcategory.title;
        setFormData({ ...formData, ...product, name, domaine_id, category_id, subcategory_id, type });
      }
    }
  
    // Open the modal after resetting the form
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setFormData({
      category: "",
      domain: "",
      subcategory: "",
      name: "",
      duration: "",
      type: "product",
      is_active: 1,
    });
    setFileName("");
    setIsOpen(false); 
  }

  return (
    <>
      <MenuHeader />
      <div className="product-breadcumb">
        <div className="container">
          <div className="search">
            <select>
              <option value="">All Products</option>
              <option value="Name">All Products</option>
            </select>
          </div>
          <button onClick={() => openModal(null, null)} className="btn-add-product">
            Add
          </button>
        </div>
      </div>

      <div className="bloc-search no-background">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select>
              <option value="">Domain</option>
              {domain.length > 0 && domain?.map((row, index) => (
                <option value={row.id}>{row.title}</option>
              ))}
            </select>
            <select>
              <option value="">Category</option>
              {category?.map((row, index) => (
                <option value={row.id}>{row.title}</option>
              ))}
            </select>
            <select>
              <option value="">Duration</option>
              <option value="Name">Duration</option>
            </select>
            <select>
              <option value="">Active</option>
              <option value="Name">Active</option>
            </select>
          </div>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead className="no-background">
              <tr>
                <th>Domain</th>
                <th>Image</th>
                <th>Category</th>
                <th>Subcategory</th>
                <th>Product</th>
                <th>Subproduct</th>
                <th>Price</th>
                <th>Status</th>
                <th>Edit/Disable</th>
              </tr>
            </thead>
            <tbody>
              {products.map((row, index) => (
                <tr
                  key={index}
                  className={row.status === "Inactive" ? "disabled-item" : ""}
                >
                  <td className="domain-txt-color">
                    {row?.category?.domaine?.title}
                  </td>
                  <td>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${row.logo}`}
                      alt="Product Logo"
                      style={{ width: '50px', height: '50px' }}
                    />
                  </td>
                  <td>{row?.category?.title}</td>
                  <td>{row.subcategory?.title}</td>
                  <td>{row.name}</td>
                  <td>{row.subProducts?.map((sub, index) => (
                sub.title +", "
              ))}</td>
                  <td>{row.price}</td>
                  <td
                    className={row.status === "Active" ? "active" : "disabled"}
                  >
                    {row.status}
                  </td>
                  <td>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{ alignItems: "center" }}
                    >
                      <AntSwitch
                        defaultChecked={row.status === "Active"}
                        onChange={handleSwitchChange(index, row.id)}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    <div
                      className="edit"
                      onClick={() => setHoveredRow(prev => prev === index ? null : index)}
                      style={{ position: 'relative', marginLeft: '25px' }}
                    >
                      &#8942;
                      {hoveredRow === index && (
                        <div className="dropdown-menu">
                          <ul>
                            <li onClick={() => openModal(row, "domaine")}>Edit Domain</li>
                            <li onClick={() => openModal(row, "product")}>Edit Product</li>
                            <li onClick={() => openModal(row, "category")}>Edit Category</li>
                            {row.subcategory && 
                            <li onClick={() => openModal(row, "subcategory")}>Edit SubCategory</li>
                          }
                          </ul>
                        </div>
                      )}
                    </div>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal}>close</button>
        <h2>Ajouter un Produit/Service</h2>
        <p>If you need more info, please check out</p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-text">
              <select value={formData.type} name="type" onChange={handleChange}>
                <option value="">Add ...</option>
                <option value="domaine">Add New Domain</option>
                <option value="category">Add New Category</option>
                <option value="subcategory">Add New Subcategory</option>
                <option value="product">Add New Product</option>
                <option value="subproduct">Add New Subproduct</option>
              </select>
            </div>

            {((formData.type === "category" || formData.type === "product" || formData.type === "subproduct")) && (
              <>
                <div className="form-text">
                  <label>Domain*</label>
                  <select required name="domaine_id" onChange={handleChange} value={formData.domaine_id}>
                    <option value={formData.domain }>Select a domain</option>
                    {domain.length > 0 ? (
                      domain.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No domains available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "product" || formData.type === "subproduct") && (
              <>
                <div className="form-text">
                  <label>Category*</label>
                  <select value={formData.category_id } required name="category_id" onChange={handleChange}>
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "subcategory") && (
              <>
                <div className="form-text">
                  <label>Category*</label>
                  <select required name="category_id" onChange={handleChange}>
                    <option value="">Select a category</option>
                    {category.length > 0 ? (
                      category.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "product" || formData.type === "subproduct") &&<> 
            <div className="form-text">
              <label>Subcategory</label>
              <select value={formData.subcategory_id} name="subcategory_id"  onChange={handleChange}>
                <option value="">Select a Subcategory</option>
                  {subCategories.length > 0 ? (
                        subCategories.map((row) => (
                          <option key={row.id} value={row.id}>{row.title}</option>
                        ))
                      ) : (
                      <option value="" disabled>No Subcategories available</option>
                  )}
              </select>
            </div>
            
            </>
            } 
            {formData.type == "subproduct"
              &&
              <div className="form-text">
                <label>Product</label>
              <select name="product_id" required onChange={handleChange}>
                <option value=""></option>
                { productList && productList?.map((row, index) => (
                  <option value={row.id}>{row.name}</option>
                ))}
              </select>
              
              </div>
              }           
            <div className="form-text">
              <label>
                {formData.type === "product"
                  ? "Product"
                  : "Title"}
                *
              </label>
              <input value={formData.name || formData.title} required type="text" name="name" onChange={handleChange} />
              
            </div>
            {formData.type === "product" && (
              <div className="form-text">
                <label>Subproduct ( Facultatif )</label>
                <input type="text" name="subproduct" onChange={handleChange} />
              </div>
            )}
            <div className="form-text topMargArea">
              <label>Description*</label>
              <textarea
                required
                className="formTextArea"
                type="textarea"
                value={formData.description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {(formData.type == "product" || formData.type == "subproduct") && (
              <>
                <div className="form-text">
                  <label>Duration *</label>
                  <input
                    required={formData.type === "product" || formData.type === "subproduct"}
                    type="number"
                    id="duration"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    min="0" 
                    max="240"
                    placeholder="Enter duration (in minutes)"
                    step="1"  // Ensure the input increments by 1 minute
                  />
                </div>

                <div className="form-text">
                  <label>Price*</label>
                  <input
                    required={formData.type === "product" || formData.type === "subproduct"}
                    type="number"
                    name="price"
                    min="0" 
                    max="9999"
                    value={formData.price}
                    onChange={handleChange}
                    step="0.01"
                    placeholder="Enter price (CHF)"
                  />
                </div>
              </>
            )}

            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleChange}
                ref={fileInputRef} 
                style={{ display: "none" }}
              />
            </div>

            <div className="capability-input">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>Status</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Innactive</Typography>
                  <Switch
                    defaultChecked={formData.is_active}
                    onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "status" }}
                  />
                  <Typography>Active</Typography>
                  </Stack>
              </Stack>
            </div>

            <div className="actions">
              <button type="submit" className="btn-submit">
                Enregistrer
              </button>
              <button type="submit" onClick={closeModal} className="btn-submit">
                Annuler
              </button>
            </div>
          </form> 
        </div>
      </Modal>
    </>
  );
};

export default Product;

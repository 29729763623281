// ChooseLocation.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import image from "../assets/images/profil-picto.png";
import Modal from 'react-modal';
import { endpoints } from "../utils/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from 'sweetalert2'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Customer = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [loading, setLoading] = useState(true);   // Pour afficher un état de chargement
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(0); // Page actuelle
  const [totalPages, setTotalPages] = useState(1);   // Total des pages
  const limit = 100; // Nombre de clients par page
  const [formData, setFormData] = useState({
    id:null, //
    civility: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    birthdate: "",
    zipcode: "",
    type: "client"
  });
  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };
  // Fonction pour mettre à jour les valeurs du formulaire
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Envoyer les données du formulaire au serveur via Axios
    axiosPrivate
      .post(formData.id ? endpoints.update : endpoints.invite, formData)
      .then((response) => {
        if(response.data.status == false){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Une erreur est survenu! "+response.data.message,
            
          });

        }
          
        closeModal();
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Compte crée",
          showConfirmButton: false,
          timer: 1500
        });
        console.log("Form submitted successfully:", response.data);
        fetchCustomers();
      })
      .catch((error) => {
        // Gérer les erreurs lors de l'envoi
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu! "+error,
          
        });
        console.error("Error submitting form:", error);
      });
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosPrivate.get(
        `${endpoints.getAdminUser}/?role=ROLE_CLIENT&page=${currentPage+1}&limit=${limit}`
      ); // L'URL inclut la page et la limite

      setCustomers(response.data.users); // Assurez-vous que 'customers' correspond aux données renvoyées par votre API
      setTotalPages(response.data.totalPages || 2); // Le nombre total de pages si disponible dans la réponse
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
      setLoading(false);
    }
  };

   // Fonction pour calculer la date minimale (18 ans avant aujourd'hui)
   const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18, // Soustraire 18 ans à l'année actuelle
      today.getMonth(),
      today.getDate()
    );
    return minDate.toISOString().split("T")[0]; // Formater la date en YYYY-MM-DD
  };
  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {
    

    fetchCustomers();
  }, [currentPage]); // Recharger les données à chaque changement de page

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }};


    function openModal(customer) {
      console.log(customer)
      if (customer) {
          setFormData({ ...formData, ...customer });
      }
      setIsOpen(true);
  }
  

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setFormData({
      id:null,
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zipcode: "",
      type: "client"
    });
    setIsOpen(false);
  }

  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <div className="search">
            <div className="text-search">
              <input
                type="text"
                name=""
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select>
              <option value="">Name</option>
              <option value="Name">name</option>
            </select>
            <select>
              <option value="">Contains</option>
              <option value="Name">Contains</option>
            </select>
            <select>
              <option value="">All Filters</option>
              <option value="Name">All Filters</option>
            </select>
            <select>
              <option value="">All Tags</option>
              <option value="Name">All Tags</option>
            </select>
          </div>
          <a onClick={openModal} className="btn-add-client">
            Ajouter un client
          </a>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Image</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Registration Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {customers?.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img src={image} alt="FlySpa" />
                    </td>
                    <td>{customer.firstname} {customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>{customer.registredDate}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {customer.status}
                    </td>
                    <td>
                      <a onClick={() => openModal(customer)} className="edit">
                        Edit
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Aucun client trouvé.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="pagination-button"
            >
              Précédent
            </button>
            <span>
              Page {currentPage + 1} sur {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages - 1}
              className="pagination-button"
            >
              Suivant
            </button>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <button onClick={closeModal}>close</button>
          <h2>Ajouter un client</h2>
          <p>Si vous avez</p>
          <div className="form-inscription">
          <form onSubmit={handleSubmit}>
      <div className="form-choix">
        <div className="form-radio">
          <input
            type="radio"
            name="civility"
            value="madame"
            id="madame"
            onChange={handleChange}
          />
          <label htmlFor="madame">Madame</label>
        </div>
        <div className="form-radio">
          <input
            type="radio"
            name="civility"
            value="monsieur"
            id="monsieur"
            onChange={handleChange}
          />
          <label htmlFor="monsieur">Monsieur</label>
        </div>
      </div>

      <div className="form-text">
        <label>Prénom</label>
        <input value={formData.firstname} type="text" name="firstname" onChange={handleChange} />
      </div>

      <div className="form-text">
        <label>Nom</label>
        <input value={formData.lastname} type="text" name="lastname" onChange={handleChange} />
      </div>

      <div className="form-text">
        <label>Numéro de téléphone portable</label>
         <PhoneInput
            defaultCountry="ch"
            type="tel"
            name="phone"
            value={formData.phone}
            disableCountryGuess="true"
            //hideDropdown="true"
            forceDialCode="true"
            //onChange={handlePhoneChange}
            onChange={(phone) => handlePhoneChange(phone)}
            required
            />
      </div>

      <div className="form-text">
        <label>Adresse email</label>
        <input value={formData.email} type="email" name="email" onChange={handleChange} />
      </div>

      <div className="form-text">
        <label>Date de naissance:</label>
        <input
          type="date"
          name="birthdate"
          value={formData.birthdate}
          onChange={handleChange}
          max={calculateMinDate()} // Date maximum permise (il y a 18 ans)
        />
      </div>

      <div className="form-text">
        <label>Code ZIP</label>
        <input value={formData.zipcode} type="text" name="zip" onChange={handleChange} />
      </div>

      <button type="submit" className="btn-submit">
        {formData.id ? "Mettre à jour" : "Créer un compte" }
        
      </button>
    </form>
          </div>
      </Modal>
    </>
  );
};

export default Customer;
